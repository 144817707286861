import "styles/pages/competition.scss"

import React, { useState } from "react"
import axios from "axios"
import { graphql } from "gatsby"
import Slider from "react-slick"

import Layout from "components/layout"
import Seo from "components/seo"
import Radio from "components/Radio"

import { CompetitionQuestions } from "data/competition-2024"

const Competition = ({ data }) => {
  const today = new Date()

  const questionOneEnd = new Date("12/02/2024")
  const questionTwoEnd = new Date("12/09/2024")
  const questionThreeEnd = new Date("12/16/2024")
  const endCompetition = new Date("12/23/2024")

  const questionOne = today < questionOneEnd
  const questionTwo = today >= questionOneEnd && today < questionTwoEnd
  const questionThree = today >= questionTwoEnd && today < questionThreeEnd
  const questionFour = today >= questionThreeEnd && today <= endCompetition

  const winners = data?.wpPage?.acfCompetition?.competitionWinners2024

  const [isSending, setIsSending] = useState(false)
  const [modal, setModal] = useState(false)

  const [svar, setSvar] = useState(null)
  const [navn, setNavn] = useState("")
  const [teldubustadur, setTeldubustadur] = useState("")
  const [gota, setGota] = useState("")
  const [bygd, setBygd] = useState("")
  const [telefon, setTelefon] = useState("")
  const [error, setError] = useState(false)

  const resetForm = () => {
    setSvar(null)
    setNavn("")
    setTeldubustadur("")
    setGota("")
    setBygd("")
    setTelefon("")
  }

  const formSubmit = e => {
    e.preventDefault()
    setIsSending(true)

    setError(false)
    if (!!!svar) {
      setError(true)
      setIsSending(false)
      return
    }

    let formData = new FormData()
    formData.set("svar", svar)
    formData.set("navn", navn)
    formData.set("teldubustadur", teldubustadur)
    formData.set("gota", gota)
    formData.set("bygd", bygd)
    formData.set("telefon", telefon)

    axios({
      method: "post",
      url: `https://wp.mbm.fo/wp-json/contact-form-7/v1/contact-forms/${
        questionOne ? CompetitionQuestions[0]?.form : ""
      }${questionTwo ? CompetitionQuestions[1]?.form : ""}${
        questionThree ? CompetitionQuestions[2]?.form : ""
      }${questionFour ? CompetitionQuestions[3]?.form : ""}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        resetForm()
        setIsSending(false)
        setModal(true)
      })
      .catch(() => {
        setIsSending(false)
        console.warn("Error")
      })
  }

  const slider = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 4500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
  }

  const slidesImagesDesktop = [
    require("assets/images/competition/christmas-competition-2024.jpg").default,
  ]

  // const sliderImagesMobile = [
  //   require("assets/images/competition/christmas-competition-2024.jpg").default,
  // ]

  return (
    <Layout isContest>
      <Seo title="JÓLAKAPPINGIN 2024" />

      <section className="competition">
        <div className="competition__desktop">
          <Slider {...slider}>
            {slidesImagesDesktop?.map((item, index) => (
              <div key={index}>
                <div
                  className="competition__slide"
                  style={{ backgroundImage: `url('${item}')` }}
                ></div>
              </div>
            ))}
          </Slider>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="competition__intro">
                  <img
                    src={require("assets/images/competition/logo.png").default}
                    alt="RASKA"
                  />
                  <h1>VINN EIN STÓRAN JÓLAPAKKA</h1>
                  <p>
                    <span className="highlight">
                      við góðum mati og vørum frá Røsku
                    </span>
                  </p>
                  <p>hvønn sunnudag í advent</p>
                  <p>
                    <span className="highlight">
                      (vinnarin verður kunngjørdur mánadag)
                    </span>
                  </p>
                  <h2>LES UM VINNINGIN UNDIR KAPPINGINI</h2>
                </div>
              </div>
              {today <= endCompetition && (
                <div className="col-md-6 offset-md-1">
                  <h4 style={{ textTransform: "uppercase" }}>
                    Fyri at luttaka í kappingini,
                    <br />
                    mást tú skanna QR-kotuna á mjólkarpakkanum.
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="competition__mobile">
          <div className="competition__container">
            {/* <Slider {...slider}>
              {sliderImagesMobile?.map((item, index) => (
                <div key={index}>
                  <div
                    className="competition__slide"
                    style={{ backgroundImage: `url('${item}')` }}
                  ></div>
                </div>
              ))}
            </Slider> */}
            <div className="competition__intro">
              <img
                src={require("assets/images/competition/logo.png").default}
                alt="RASKA"
              />
              <h1>VINN EIN STÓRAN JÓLAPAKKA</h1>
              <p>
                <span className="highlight">
                  við góðum mati og vørum frá Røsku
                </span>
              </p>
              <p>hvønn sunnudag í advent</p>
              <p>
                <span className="highlight">
                  (vinnarin verður kunngjørdur mánadag)
                </span>
              </p>
              <h2>LES UM VINNINGIN UNDIR KAPPINGINI</h2>
              <h3 className="highlight">
                Fyri at luttaka í kappingini,
                <br />
                mást tú skanna QR-kotuna á mjólkarpakkanum.
              </h3>
            </div>
          </div>
        </div>

        <div className="competition__form-group">
          <div className="container-fluid">
            {today <= endCompetition && (
              <div className="competition__tile">
                <h3>Set røttu orðini inn:</h3>
                <h4>
                  {questionOne && CompetitionQuestions[0]?.question}
                  {questionTwo && CompetitionQuestions[1]?.question}
                  {questionThree && CompetitionQuestions[2]?.question}
                  {questionFour && CompetitionQuestions[3]?.question}
                </h4>

                <form className="competition__form" onSubmit={formSubmit}>
                  {CompetitionQuestions[
                    questionOne
                      ? 0
                      : questionTwo
                      ? 1
                      : questionThree
                      ? 2
                      : questionFour && 3
                  ]?.options?.map((item, index) => (
                    <Radio
                      key={index}
                      name="question"
                      value={svar}
                      onChange={() => setSvar(item)}
                    >
                      {item}
                    </Radio>
                  ))}

                  <input
                    type="text"
                    name="navn"
                    value={navn}
                    onChange={e => setNavn(e.target.value)}
                    placeholder="Navn*"
                    required
                  />
                  <input
                    type="text"
                    name="teldubustadur"
                    value={teldubustadur}
                    onChange={e => setTeldubustadur(e.target.value)}
                    placeholder="Teldubústaður*"
                    required
                  />
                  <input
                    type="text"
                    name="gota"
                    value={gota}
                    onChange={e => setGota(e.target.value)}
                    placeholder="Gøta*"
                    required
                  />
                  <input
                    type="text"
                    name="bygd"
                    value={bygd}
                    onChange={e => setBygd(e.target.value)}
                    placeholder="Bygd/Býur*"
                    required
                  />
                  <input
                    type="text"
                    name="telefon"
                    value={telefon}
                    onChange={e => setTelefon(e.target.value)}
                    placeholder="Telefon"
                  />
                  {!!error && (
                    <small className="competition__form-error">
                      Tú mást svara spurninginum við at seta merki við eitt svar
                    </small>
                  )}
                  <button type="submit">
                    {isSending ? "Sending" : "Send"}
                  </button>
                  {/* <small>Í samstarvi við Atlantic Airways</small> */}
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            {winners && (
              <div className="col-md-5">
                <div className="competition__tile">
                  <h4>Vinnarar:</h4>
                  {/* {data.wpPage.acfCompetition.competitionWinners2023 && (
                  <span style={{ marginBottom: "20px", display: "block" }}>
                    Tit sum hava svarað øðrum spurningi, sum kom ov tíðliga út,
                    vóru eisini við í kappingini.
                  </span>
                )} */}
                  <ol reversed>
                    {winners.map((item, index) => (
                      <li key={index}>{item.competitionWinner}</li>
                    ))}
                  </ol>
                </div>
              </div>
            )}

            {today > questionOneEnd && (
              <div
                className={`col-md-6 hidden-mobile${
                  winners ? " offset-md-1" : " offset-md-3"
                }`}
              >
                <div className="competition__tile">
                  <h4>RØtt SVAR:</h4>

                  <ol>
                    <li>
                      1. {CompetitionQuestions[0]?.question}{" "}
                      <span>{CompetitionQuestions[0]?.answer}</span>
                    </li>
                    {today > questionTwoEnd && (
                      <li>
                        2. {CompetitionQuestions[1]?.question}{" "}
                        <span>{CompetitionQuestions[1]?.answer}</span>
                      </li>
                    )}
                    {today > questionThreeEnd && (
                      <li>
                        3. {CompetitionQuestions[2]?.question}{" "}
                        <span>{CompetitionQuestions[2]?.answer}</span>
                      </li>
                    )}
                    {today > endCompetition && (
                      <li>
                        4. {CompetitionQuestions[3]?.question}{" "}
                        <span>{CompetitionQuestions[3]?.answer}</span>
                      </li>
                    )}
                  </ol>
                </div>
              </div>
            )}
          </div>

          <div className="competition__info">
            <p>VINNINGUR:</p>
            <p className="highlight">
              2 Julius dunnur 2,8 kg
              <br />
              1 strektur bógvur NZ, 1,3 – 1,6 kg
              <br />
              1 lambstjógv NZ 2,0 - 2,3 kg
              <br />
              1 lambsryggur NZ 1,2 kg
              <br />
              1 fleskasteik, uml. 1,35 kg
              <br />
              1 nakkasteik v/svør, 2 kg
              <br />
              1 pk enskur búffur, 2 x 250gr
              <br />
              1 pk skivaður royktur laksur frá Heimland, 200gr
              <br />
              1 dós karamellur, 600 gr
              <br />1 krt blandað sodavatn 4 x 6 pk
            </p>
          </div>
        </div>
      </section>

      {modal && (
        <>
          <button
            className="competition__modal-overlay"
            type="button"
            onClick={() => setModal(false)}
          />
          <div className="competition__modal">
            <img
              src={
                require("assets/images/competition-modal-header.png").default
              }
              alt=""
            />
            <p>Títt svar er sent til Røsku</p>
          </div>
        </>
      )}
    </Layout>
  )
}

export default Competition

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDo4ODM=" }) {
      id
      acfCompetition {
        competitionWinners2024 {
          competitionWinner
        }
      }
    }
  }
`
