export const CompetitionQuestions = [
  {
    question: "Lítla xxxxx xxxxx mín",
    options: ["fitta omma", "fitti abbi", "fitta nissa"],
    answer: "fitta nissa",
    form: "2225",
  },
  {
    question: "Góði jólamaðurin, læn mær xxxx xxxx",
    options: ["nøs tína", "oyra títt", "fingur tín"],
    answer: "oyra títt",
    form: "2226",
  },
  {
    question: "Eg eri so spent til xxxx",
    options: ["jóla", "ólavsøku", "føðingardagin"],
    answer: "jóla",
    form: "2227",
  },
  {
    question: "Eitt barn er føtt í Xxxxxxxx",
    options: ["Klaksvík", "Betlehem", "Famjin"],
    answer: "Betlehem",
    form: "2228",
  },
]
